html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-size: 2em;
}

.container {
    display: table;
    height: 100%;
    width: 100%;
    
}
#title-bar {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background-color: #444;
    color: #fff;
}
form {
    margin-top: 5em;
    ;
    width: 70%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
}

input,
select {
    margin-bottom: 2em;
    font-size: 2em;
}

#success-message {
    display: none;
    text-align: center;
    font-size: 1.7em;
}
#success-message h2 {
    color: rgb(89, 168, 74);
}
#success-message p {
    color: #444;
    font-weight: bold;
}
#error-log {
    padding: 1em;
    border: 2px dashed #fff;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: bold;
    width: 50%;
    color: #fff;
    display: none;
    font-size: 1em;
}
#cookie-disclaimer {
    font-size: 1.7em;
    color: #fff;
    font-weight: bold;
    text-align: center;
}
.top {
    display: table-row;
    height: 700px;
    padding: 10px 15%;
}

.bottom {
    display: table-row;
    height: 100%;
    background: rgb(89, 168, 74);
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#check-in-message,
#check-out-message {
    display: none;
}
#spinner img{
    animation: rotation 1s steps(12, end) infinite;
    opacity: 50%;
    height: 100px;
    display: none;
}
@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 600px) {

    /* For tablets: */

}

@media only screen and (min-width: 768px) {

    /* For desktop: */
body {
    font-size: 0.5em;
    
}

.top {
    height: 300px;
}
}