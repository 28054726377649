html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 2em;
}

.container {
  width: 100%;
  height: 100%;
  display: table;
}

#title-bar {
  text-align: center;
  color: #fff;
  background-color: #444;
  width: 100%;
  padding: 10px 0;
}

form {
  flex-direction: column;
  width: 70%;
  max-width: 700px;
  margin-top: 5em;
  display: flex;
}

input, select {
  margin-bottom: 2em;
  font-size: 2em;
}

#success-message {
  text-align: center;
  font-size: 1.7em;
  display: none;
}

#success-message h2 {
  color: #59a84a;
}

#success-message p {
  color: #444;
  font-weight: bold;
}

#error-log {
  color: #fff;
  background-color: #ffffff1a;
  border: 2px dashed #fff;
  border-radius: 10px;
  width: 50%;
  padding: 1em;
  font-size: 1em;
  font-weight: bold;
  display: none;
}

#cookie-disclaimer {
  color: #fff;
  text-align: center;
  font-size: 1.7em;
  font-weight: bold;
}

.top {
  height: 700px;
  padding: 10px 15%;
  display: table-row;
}

.bottom {
  background: #59a84a;
  height: 100%;
  display: table-row;
}

.content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#check-in-message, #check-out-message {
  display: none;
}

#spinner img {
  opacity: .5;
  height: 100px;
  animation: 1s steps(12, end) infinite rotation;
  display: none;
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (width >= 768px) {
  body {
    font-size: .5em;
  }

  .top {
    height: 300px;
  }
}
/*# sourceMappingURL=index.2cfd0de2.css.map */
